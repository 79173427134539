import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageLinkItem from "../components/ImageLinkItem"

import { theme } from "../styles/theme"
import "../styles/index.css"

import listImage_ppi from "../images/indexlistimages/listimage-positive-patient-identification@2x.png"
import listImage_pc from "../images/indexlistimages/listimage-patient-consent@2x.png"
import listImage_tra from "../images/indexlistimages/listimage-taco-risk-assessment@2x.png"
import listImage_bc from "../images/indexlistimages/listimage-bedside-checklist@2x.png"
import listImage_com from "../images/indexlistimages/listimage-compatibility@2x.png"
import listImage_sr from "../images/indexlistimages/listimage-specific-requirements@2x.png"
import listImage_admin from "../images/indexlistimages/listimage-administration@2x.png"
import listImage_trg from "../images/indexlistimages/listimage-transfusion-reaction-guidance@2x.png"

const IndexPage = () => {
  return (
    <Layout homeLink={false}>
      <SEO title="Home" />
      <div css={theme.components.row}>
        <h1 css={[theme.components.title, theme.components.titleLarge]}>
          Welcome to Blood Assist
        </h1>
        <h2
          className="mb-gap"
          css={[
            theme.components.title,
            theme.components.titleLarge,
            theme.components.titleRed,
          ]}
        >For Patients 18 Years and Over.</h2>

        <ul className="links-grid mb-gap">
          <li>
            <ImageLinkItem
              destination="/positive-patient-identification"
              imageName={listImage_ppi}
              title="Positive Patient Identification"
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/patient-consent"
              imageName={listImage_pc}
              title="Patient Consent"
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/taco/"
              imageName={listImage_tra}
              title="TACO Risk Assessment"
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/bedside-checklist"
              imageName={listImage_bc}
              title="Bedside Checklist"
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/compatibility/"
              imageName={listImage_com}
              title="Compatibility of Blood Components"
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/requirements/"
              imageName={listImage_sr}
              title="Specific Requirements"
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/administration/"
              imageName={listImage_admin}
              title="Administration"
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/transfusion-reaction/"
              imageName={listImage_trg}
              title="Transfusion Reaction Guidance"
            />
          </li>
          <li>
            <div css={styles.btnContainer}>
              <Link
                to="/about"
                css={[
                  theme.components.button,
                  theme.components.buttonGrey,
                  styles.button,
                ]}
              >
                About
              </Link>
              <Link
                to="/disclaimer"
                css={[
                  theme.components.button,
                  theme.components.buttonGrey,
                  styles.button,
                ]}
              >
                Disclaimer and Privacy Policy
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

const styles = {
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(50% - 0.5rem)",
  },
}

export default IndexPage
